<template>
  <div class="container crm">

    <Head></Head>
    <main>
      <div class="banner" style="background: #00BCD4;">
        <div class="wrap">
          <div class="txt">
            <h1>企赢新零售店长通</h1>
            <div class="subtitle">全链路一体化数字药房解决方案</div>
            <div>· 各个门店每个店员专属二维码，极大地方便了总部考核，有效地促进了新零售业务的推行。</div>

            <div>· 每笔订单均会根据营销活动、积分等情况来作出准确的销帐方案，财务对帐十分轻松。</div>

            <div>· 可设置各种会员优惠活动，多重优惠促活，提高复购率，由店员来维护自己名下的顾客，更能让用户产生信赖。</div>
          </div>
          <div class="img">
            <img src="../assets/c4.png">
          </div>
        </div>
      </div>
      <!--  -->
      <div class="bg2">
        <div class="block wrap">
          <div class="pic">
            <img src="../assets/01.jpg">
          </div>
          <div class="info">
            <DIV><img src="../assets/01logo.png" width="80px" height="80px"></DIV>
            <h2>店长通</h2>
            <div class="text">企赢动力新零售系统</div>
            <span class="sub-text"><a href="http://ydxlsdzapi.bjqydl.com/downloads/ydxls_V1.0.0.5.apk"><el-button
                  type="primary" round>立即下载</el-button></a></span>
          </div>
        </div>
      </div>
      <!--  -->
    </main>
    <Foot></Foot>
  </div>
</template>

<script>
import Foot from '../components/foot'
import Head from '../components/head'
export default {
  components: {
    Foot,
    Head
  },
  data() {
    return {
      list: [{
        title: '销售过程管理',
        desc: '营销过程全自动化，提升效率',
        src: require('../assets/crm01.jpg')
      }, {
        title: '客户资源管理',
        desc: '多渠道统一管理，深度经营企业客户资源',
        src: require('../assets/crm02.jpg')
      }, {
        title: '销售行为管理',
        desc: '大数据分析，有效监控销售行为',
        src: require('../assets/crm03.jpg')
      }],
    }
  }
}
</script>

<style lang="less" scoped>
.bg {
  background: #f8f8f8;
  padding: 60px 0;
}

.bg2 {
  background: #f8f8f8;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  main {
    flex: 1;

    .banner {
      //background-image: url('../assets/banner-index1.png');
      background-position: center;
      background: #2979FF;

      .wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 520px;
        line-height: 36px;
        color: #FFF;

        h1 {
          font-size: 36px;
          font-weight: bold;
        }

        .subtitle {
          font-size: 20px;
        }

        .img {
          width: 35%;
          overflow: hidden;
          height: 100%;
          display: flex;
          align-items: flex-end;

          img {
            width: 100%;
          }
        }

      }
    }

    .parent {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 20px;

      li {
        padding: 20px;
        background: #fff;
        border-bottom: 2px solid #fff;
        position: relative;

        .img {
          overflow: hidden;
          position: relative;
        }

        figcaption {
          text-align: center;

          .title {
            margin-top: 40px;
            font-size: 18px;
            line-height: 36px;

          }

          .desc {
            font-size: 14px;
            margin-bottom: 20px;
            color: #acb2ba;
          }
        }

        img {
          width: 100%;
          transition: all 0.25s ease;
        }

        &::after {
          content: '';
          width: 0;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 50%;
        }

        &:hover {
          box-shadow: 0 0 10px rgba(0, 0, 0, .1);

          img {
            transform: scale(1.1);
          }

          &::after {
            width: 100%;
            left: 0;
            background: #2979FF;
            transition: all .8s;

          }
        }

        .project {
          text-align: center;
        }
      }
    }

    .block {
      padding: 100px 0;
      display: flex;
      //justify-content: space-between;
      align-items: center;

      .info {
        line-height: 40px;
        padding-left: 200px;
        text-align: center;

        h2 {
          font-size: 36px;
          font-weight: bold;
        }

        .text {
          font-size: 20px;
        }

        .sub-text {
          color: #acb2ba;
        }

        .btn {
          margin-top: 20px;
        }
      }

      .pic {
        img {
          width: 400px;
        }
      }
    }
  }

}
</style>
